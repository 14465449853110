import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import road_n_street_sweeper from "../../public/static/images/products/road_n_street_sweeper.jpg"

function RoadStreetSweeper() {

  const prodDetail = {
    "name": "ROAD & STREET SWEEPERS",
    "detail": {
      "about" : ["Our range of products include Road Sweeping Machine such as Mechanical Sweeping Machine, Tractor Attach Road Sweeping Machine and Truck Mounted Road Sweeping Machine."],
      "advantages": [
        "Road sweeping machine. The provided machine is manufactured using premium quality material and latest techniques.",
        "Adhere to industry standards and quality norms.",
        "The offered machine is examined on the set quality parameters to give quality products."
      ]
    },
    "tech_data": {
      "column1": [
        "Code",
        "Chassis",
        "Body Size",
        "Auxiliary Engine",
        "Water Tank",
        "Blower Capacity",
        "Arrangement"
      ],
      "column2": [
        "IESWEEP-14",
        "2.5 Ton –16 Ton or Customized",
        "1.1 M3 - 6.5 M3 or Customized",
        "Make –Kirloskar (49-105 HP Capacity)",
        "300-600 Ltrs. or Customized",
        "600-15000 M3 /Hr. or depend M3",
        "2 nos. of side brush & 1 nos. of centre brushing for cleaning the road by help of Engine, hydraulic pump, motor, cylinder & others controlling systems."
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={road_n_street_sweeper} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default RoadStreetSweeper
